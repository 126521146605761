import React from 'react';
import { Link } from 'react-router-dom';
import '../static/scss/footer.scss';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedinIn, faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons"

const Footer = () => {

    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 f-copyrights text-center text-md-left mb-2 mb-md-0">
                        <p>
                        © 2020 Ourovoros.io All rights reserved.
                        </p>
                    </div>
                    <div className="col-12 col-md-4 f-menu d-flex justify-content-center">
                        <ul>
                            <li><Link to="/" title="Home">Home</Link></li>
                            <li><Link to="/about" title="About">About</Link></li>
                            <li><Link to="/work" title="Work">Work</Link></li>
                            <li><Link to="/contact" title="Contact">Contact</Link></li>
                            <li className="last"><Link to="/blog" title="Blog">Blog</Link></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-4 f-socials d-flex justify-content-md-end justify-content-center mt-2 mt-md-0">
                        {/* <ul>
                            <li><a href="/"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                            <li><a href="/"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                            <li><a href="/"><FontAwesomeIcon icon={faInstagram} /></a></li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </footer>
    )

}

export default Footer;
