import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Fallback from './pages/Fallback'; 

import AOS from "aos";
import "aos/dist/aos.css";

import 'bootstrap';

import './static/scss/main.scss'; 
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/js/dist/dropdown';


const NotFound = lazy(() => import('./pages/NotFound'));
const Homepage = lazy(() => import('./pages/Homepage'));
const About = lazy(() => import('./pages/About'));
const Work = lazy(() => import('./pages/Work'));
const Contact = lazy(() => import('./pages/Form'));
const Blog = lazy(() => import('./pages/Blog'));
const Admin = lazy(() => import('./pages/admin/Admin'));
const Login = lazy(() => import('./pages/admin/Login'));
const Project_add = lazy(() => import('./pages/admin/project_add'));
const Article_add = lazy(() => import('./pages/admin/article_add'));
const Project_edit = lazy(() => import('./pages/admin/project_edit'));
const Article_edit = lazy(() => import('./pages/admin/article_edit'));

function App() {

  const [loggedIn, setLoggedIn] = useState(sessionStorage.getItem("token"))

  // useEffect(() => {
  //   if(sessionStorage.getItem("token")) {
  //     setLoggedIn(true)
  //   }
  // }, []);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);


  return (
    <BrowserRouter basename="/">
      <Suspense fallback={<Fallback />}>
        <Routes>
          <Route exact path="/" element={<Homepage/>} />
          <Route exact path="/about" element={<About/>} />
          <Route exact path="/work" element={<Work/>} />
          <Route exact path="/contact" element={<Contact/>} />
          <Route exact path="/blog" element={<Blog/>} />

         
          <Route path="/admin/login" element={loggedIn ? <Navigate to="/admin" /> : <Login />}/>

          <Route exact path="/admin" element={<Admin/>} />
          <Route exact path="/project_add" element={<Project_add/>} />
          <Route exact path="/article_add" element={<Article_add/>} />
          <Route exact path="/project_edit/:id" element={<Project_edit/>} />
          <Route exact path="/article_edit/:id" element={<Article_edit/>} />
          <Route element={<NotFound/>} status={404} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
