import React from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';

const Fallback = () => {

    return (
        <>
            <Header />
            <Footer />
        </>
    )

}

export default Fallback;
