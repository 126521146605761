import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import '../static/scss/header.scss';

import logo from '../static/imgs/Group39920.svg'
// import globe from '../static/imgs/Globe.svg'
// import globe_gray from '../static/imgs/GlobeGray.svg'
 
const Header = () => {

    // const [nav, setNav] = useState(false);

    // useEffect(() => {
    //     let navBar = document.querySelector('.navbar')

    //     if(navBar)
    //         setNav(navBar)
    // })

    // const [show, setShow] = useState(false);
    // const showDropdown = (e)=>{
    //     setShow(!show);
    // }
    // const hideDropdown = e => {
    //     setShow(false);
    // }
    // const showDropdownMenu = (e)=>{
    //     setShow(true);
    // }
    // const hideDropdownMenu = e => {
    //     setShow(false);
    // }

    // window.onscroll = function() {
    //     let offset = window.scrollY
    //     offset = offset * 0.4
 
    //     let nav = document.querySelector(".navbar");
    //     let navBtn = document.querySelector(".navbar-toggler");

    //     if(offset === 0){
    //         nav.classList.remove('stuck');
    //         nav.classList.remove('fixed-top');
    //         navBtn.classList.remove('navbar-toggler-bg');
    //     }else{
    //         nav.classList.add('stuck');
    //         nav.classList.add('fixed-top');
    //         navBtn.classList.add('navbar-toggler-bg');
    //     }
    // };


    return (
        <>
        <header>
            <nav className="navbar navbar-expand-lg trigger sticky-top-nav navbar-dark">
                <div className="container">
                    <Link className="navbar-brand" to="/"><img src={logo} className="img-fluid" alt="Ourovoros Logo" title="Ourovoros Logo" /></Link>
                    
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="mynavbar">
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item">
                            <NavLink activeClassName="selected" className="nav-link" to="/" title="Home">Home</NavLink>
                            </li>
                            <li className="nav-item">
                            <NavLink activeClassName="selected" className="nav-link" to="/about" title="About">About</NavLink>
                            </li>
                            <li className="nav-item">
                            <NavLink activeClassName="selected" className="nav-link" to="/work" title="Work">Work</NavLink>
                            </li>
                            <li className="nav-item">
                            <NavLink activeClassName="selected" className="nav-link" to="/contact" title="Contact">Contact</NavLink>
                            </li>
                            <li className="nav-item">
                            <NavLink activeClassName="selected" className="nav-link" to="/blog" title="Blog">Blog</NavLink>
                            </li>
                        </ul>
                        {/* <div className="d-flex nav-lang">

                            <div class="dropdown">
                                <button 
                                className="btn btn-globe nav-link dropdown-toggle" 
                                onMouseEnter={showDropdown} 
                                onMouseLeave={hideDropdown}
                                >
                                    <img src={globe} alt="" className="img-fluid" />
                                </button>
                                <ul className={"dropdown-menu lang-menu " + (show ? 'show' : 'hidden')}
                                onMouseEnter={showDropdownMenu} 
                                onMouseLeave={hideDropdownMenu}>
                                    <li className="globe"><img src={globe_gray} alt="" className="img-fluid" /></li>
                                    <li><a class="dropdown-item" href="/">GR</a></li>
                                    <li><a class="dropdown-item" href="/">EN</a></li>
                                    <li><a class="dropdown-item" href="/">SP</a></li>
                                </ul>
                            </div>

                        </div> */}
                    </div>
                </div>
            </nav>
        </header>
        <span className="position-absolute trigger">
        </span>
        </>
    )

}

export default Header;
